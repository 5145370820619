<template>
  <div class="containerMain">
    <AppHeader />
    <main>
      <router-view />
    </main>
    <AppFooter />
  </div>
</template>

<script src="./Component-App.js"></script>

<style lang="scss" src="./App.scss"></style>
